import { AnalysisIndicatorGroupModel, AnalysisIndicatorModel } from 'pages/analysis/models';
import {AnalysisStateIndicatorsModel, AnalysisStateModel, RequestParamsModel} from 'pages/analysis/models/analysis-state.model';
import {Component, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges, Output, EventEmitter, ChangeDetectorRef, ElementRef} from '@angular/core';
import {AnalysisService} from 'pages/analysis/services/analysis-service/analysis.service';
import {EnvironmentService} from 'components/app/services/environment/environment.service';
import {CdkDragEnter} from '@angular/cdk/drag-drop';
import {Subscription} from 'rxjs';
import {VisualizationModel } from 'components/diagram/interfaces/diagram-options.model';
import {AnalysisDataService} from 'pages/analysis/services/analysis-data/analysis-data.service';
import {ExploreRequestModel} from 'components/common/interfaces/explore.request.model';
import { cloneDeep, union } from 'lodash';
import {Globals} from 'components/shared/globalData';
import {environmentCommonConfig} from 'components/app/services/environment/environment-common.config';

@Component({
  selector: 'ic-sidebar-indicators',
  templateUrl: 'sidebar-indicators.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SidebarIndicatorsComponent implements OnChanges {
  @Input() indicators!: AnalysisStateIndicatorsModel;
  @Output() indicatorToggle = new EventEmitter<string[]>();
  transferringItem: string | undefined;
  @Input() activeTabIndex!: number;
  @Input() state!: AnalysisStateModel;
  @Input() reputationGrp: boolean = false;
  indicatorGroups: AnalysisIndicatorGroupModel[] = [];
  visibleIndicatorGroups: AnalysisIndicatorGroupModel[] = [];
  activeIndicator!: AnalysisIndicatorModel|null;
  detailsOpen = false;
  detailsClosing = false;
  searchQuery = '';
  private minimumSearchQueryLength = 2;
  env = this.environmentService.getEnvironment();
  visualizations: VisualizationModel[];
  visSubscription: Subscription;
  visSupportedIndicators: string[];
  IS_COLLAB_INDICATORS_ON: boolean = false;
  constructor(private cd: ChangeDetectorRef, private environmentService: EnvironmentService,
              private analysisService: AnalysisService, private elementRef: ElementRef, private analysisDataService: AnalysisDataService,
              private globals: Globals) {

    this.visSubscription = analysisDataService.currentVis.subscribe((vis) => {
      if (this.visualizations) {
        let currentVisualization = this.visualizations.find(visualizations => visualizations.type === vis) as VisualizationModel;
        this.visSupportedIndicators = currentVisualization.dimensions ? currentVisualization.dimensions[0].indicators : [];
        this.cd.detectChanges();
      }
    });

    this.IS_COLLAB_INDICATORS_ON = this.globals.toggleData[environmentCommonConfig.splitioTreatment.isCollabIndicatorsOn] === 'on';

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.state && changes.state.currentValue && changes.state.currentValue.filters.values.schema.is === 'GIPP') {
      this.reputationGrp = true;
    } else {
      this.reputationGrp = false;
    }
    if (changes.indicators && changes.indicators.currentValue) {
      this.updateIndicators(this.indicators.groups);
    }

    if (changes.state && changes.state.currentValue && !this.visualizations) {
      this.getVisualizationsForAllIndicators(changes.state.currentValue.request);
    }
  }

  getVisualizationsForAllIndicators(request: ExploreRequestModel) {
    // @ts-ignore
    const allIndicators = Object.keys(this.visibleIndicatorGroups).reduce((acc, item, index) => {
      if (this.visibleIndicatorGroups[index] && this.visibleIndicatorGroups[index]!.indicators && this.visibleIndicatorGroups[index]!.indicators!.length > 0) {
        const indicators = this.visibleIndicatorGroups[index]!.indicators!.map(indicator => indicator.name);
        acc.push(...indicators);
        return acc;
      }
    }, [] as string[]);
    const req = cloneDeep(request);
    req.indicators = union(req.indicators, allIndicators);
    this.analysisDataService.getVisualizations(this.analysisService.config, req as RequestParamsModel).subscribe((visualizations) => {
        this.visualizations = visualizations;
        this.cd.detectChanges();
    });

  }

  ngOnDestroy() {
    if (typeof(this.visSubscription) !== 'undefined') {
      this.visSubscription.unsubscribe();
    }
  }

  private updateIndicators(groups: AnalysisIndicatorGroupModel[]) {
    this.indicatorGroups = groups.map(group => ({
      ...group,
      visibleIndicators: group.indicators ? [...group.indicators.filter((indicator) => !indicator.hidden)] : [],
    }));
    this.visibleIndicatorGroups = [...this.indicatorGroups];
  }

  openIndicator(indicator: AnalysisIndicatorModel, indicatorDetails: HTMLElement) {
    this.activeIndicator = indicator;
    this.detailsOpen = true;
    const y = indicatorDetails.getBoundingClientRect().top + window.pageYOffset - 100;
    window.scrollTo(0, y);
  }

  onDetailsClose() {
    const closeAnimationDuration = 350; // must match the CSS animation duration
    this.detailsClosing = true;
    window.setTimeout(() => {
      this.detailsOpen = false;
      this.detailsClosing = false;
      this.activeIndicator = null;
    }, closeAnimationDuration);
  }

  onIndicatorToggle(names: string[]) {
    this.indicatorToggle.emit(names);
    this.activeIndicator!.enabled = !this.activeIndicator!.enabled;
    this.cd.detectChanges();
  }

  onSearchInputChange(value: string) {
    this.searchQuery = value;
    if (value.length >= this.minimumSearchQueryLength) {
      this.filterIndicatorsBySearchQuery(value);
    } else if (value.length === 0) {
      this.resetIndicators();
    }
  }

  private filterIndicatorsBySearchQuery(query: string) {
    const matchingPatten = new RegExp(query, 'i');
    this.visibleIndicatorGroups = this.indicatorGroups
      .filter(group => {
        if (group.indicators) {
          group.visibleIndicators = group.indicators.filter(indicator => !indicator.hidden && matchingPatten.test(indicator.title));
          return group.visibleIndicators.length > 0;
        }
        return false;
      });
  }

  private resetIndicators() {
    this.visibleIndicatorGroups = this.indicatorGroups.map(group => ({
      ...group,
      visibleIndicators: group.indicators ? [...group.indicators] : [],
    }));
  }

  entered(event: CdkDragEnter<AnalysisIndicatorModel>) {
    this.transferringItem = event.container.id === 'sidebar-indicator' ? undefined : event.item.data.name;
    this.cd.detectChanges();
  }

  exited() {
    this.transferringItem = undefined;
    this.cd.detectChanges();
  }

  dragStart() {
    this.elementRef.nativeElement.closest('body').style.cursor = 'move';
  }

  dragEnd() {
    this.elementRef.nativeElement.closest('body').style.cursor = 'unset';
  }

  disableDrag(indicator:  AnalysisIndicatorModel) {
    return indicator.enabled ||
      (this.activeTabIndex === 1 && this.visSupportedIndicators && !this.visSupportedIndicators.includes(indicator.name));
  }

}
