import { AnalysisBenchmarkModel, AnalysisFilterModel, AnalysisIndicatorModel } from 'pages/analysis/models';
import { AnalysisFiltersService } from 'components/common/services/analysis-filters/analysis-filters.service';
import { AnalysisIndicatorService } from '../analysis-indicator/analysis-indicator.service';
import { assign, pickBy, find, isUndefined, uniq, cloneDeep, includes, isPlainObject, isArray, intersection } from 'lodash';
import { BenchmarkModel } from 'components/analytics/interfaces/benchmark.interface';
import { DataRepositoryService } from 'components/rest/services/data-repository/data-repository.service';
import { DatasetsRepositoryService } from 'components/rest/services/datasets-repository/datasets-repository.service';
import { ExploreTileResponseModel } from 'components/common/interfaces/exploretile.response.model';
import { Indicator, IndicatorResponseModel } from 'components/common/interfaces/indicator.interface';
import {Injectable} from '@angular/core';
import { mergeMap, map, shareReplay, tap } from 'rxjs/operators';
import { MetadataRepositoryService } from 'components/rest/services/metadata-repository/metadata-repository.service';
import {Observable, of, Subject} from 'rxjs';
import { ReportsRepositoryService } from 'components/rest/services/reports-repository/reports-repository.service';
import { ReportTileModel } from 'components/common/interfaces/report-tile.model';
import { RequestParamsModel, AnalysisStateConfig, AnalysisStateFiltersModel, AnalysisStateIndicatorsModel } from 'pages/analysis/models/analysis-state.model';
import { SerializedExploreStateModel } from 'components/analytics/interfaces/expore-state.interface';
import { UserService } from 'components/auth/services/user/user.service';
import { VisualizationModel } from 'components/diagram/interfaces/diagram-options.model';
import { FilterModel, FiltersResponseModel, FullFilterValueModel } from 'components/analytics/interfaces/filter.interface';
import { EnvironmentService } from 'components/app/services/environment/environment.service';
import { DiagramLongType } from 'components/diagram/services/diagram-options/diagram-options.config';
import { UserStorageService } from 'components/analytics/services/user-storage/user-storage.service';
import { ReportTileRequestModel } from 'components/common/interfaces/report.model';
import {Globals} from 'components/shared/globalData';
import { AnalysisEntityService } from 'components/common/services/analysis-entity/analysis-entity.service';


@Injectable()
export class AnalysisDataService {
  currentVis = new Subject<string>();
  // tslint:disable-next-line:no-any
  env: { [key: string]: any; };
  constructor(
    private dataRepository: DataRepositoryService,
    private datasetsRepository: DatasetsRepositoryService,
    private filtersService: AnalysisFiltersService,
    private indicatorService: AnalysisIndicatorService,
    private metadataRepository: MetadataRepositoryService,
    private reportsRepository: ReportsRepositoryService,
    private userService: UserService,
    private environmentService: EnvironmentService,
    private userStorage: UserStorageService,
    public globalData: Globals,
    private analysisEntityService: AnalysisEntityService,
  ) {
    this.env = this.environmentService.getEnvironment();
  }

  getExcludeValues(
    items: string[],
    filters: AnalysisStateFiltersModel,
    stateConfig: AnalysisStateConfig,
    requestParams: RequestParamsModel,
    act: 'exclude' | 'include'
  ) {
    const isFilterExist = (name: string) => !!find(filters.filters, { name });

    const getExcludeVals = (excludeResults: { [key: string]: object }) => pickBy(
      excludeResults,
      (_val, key: string) => isFilterExist(key)
    ) as { [key: string]: { [p: string]: string[] } };
    return this.dataRepository.exclude(
      isNaN(Number(stateConfig.datasetId)) ? stateConfig.datasetId : Number(stateConfig.datasetId),
      stateConfig.entityId,
      requestParams, items,
      stateConfig.queryDataCollection
    ).then(
      (excludeResults) => {
        const excludeVals = getExcludeVals(excludeResults);
        return Object.keys(excludeVals).reduce((acc, filterName) => {
          const isOrNot = act === 'exclude' ? 'not' : 'is';
          const excludedValues = excludeVals[filterName].not;

          if (excludedValues) {
            if (stateConfig.entityId === 'department' && (filterName === 'departments' || filterName === 'deptHierarchy')) {
              acc[filterName] = { is: excludedValues };
            } else if (isOrNot === 'not') {
              const alreadyExcludedOption = filters.values[filterName] && filters.values[filterName].not;
              if (alreadyExcludedOption && isArray(alreadyExcludedOption)) {
                acc[filterName] = { not: uniq(excludedValues.concat(alreadyExcludedOption)) };
              } else {
                acc[filterName] = { not: excludedValues };
              }
            } else {
              acc[filterName] = { is: excludedValues };
            }

          } else {
            acc[filterName] = excludeVals[filterName];
          }
          return acc;
        }, {} as { [key: string]: { [p: string]: string[] } });
      }
    );
  }

  getExploreChildItems(
    stateConfig: AnalysisStateConfig,
    requestParams: RequestParamsModel,
  ) {
    const { datasetId, entityId, queryDataCollection } = stateConfig;

    return this.dataRepository.explore(
      datasetId,
      entityId,
      requestParams,
      queryDataCollection
    ).pipe(
      mergeMap((data) => {
        return of(data);
      }),
      shareReplay(),
    );
  }

  getExploreItems(
    currentItems: Observable<ExploreTileResponseModel>,
    stateConfig: AnalysisStateConfig,
    requestParams: RequestParamsModel,
  ) {
    if (requestParams.filters && requestParams.filters.hasOwnProperty('jrnkey')) { // @ts-ignore
      Object.defineProperty(requestParams.filters, 'collection', Object.getOwnPropertyDescriptor(requestParams.filters, 'jrnkey'));
    }
    const { datasetId, entityId, queryDataCollection } = stateConfig;
    const oldExploreItems = requestParams.skip > 0 ? currentItems : undefined;
    if (this.isValidRequestForEntity(stateConfig.entityId, requestParams.indicators)) {
      return this.dataRepository.explore(
        datasetId,
        entityId,
        requestParams,
        queryDataCollection
      ).pipe(
        mergeMap((data) => {
          if (oldExploreItems) {
            return oldExploreItems.pipe(
              map((oldData) => assign(data, { items: (oldData.items || []).concat(data.items) }))
            );
          } else {
            return of(data);
          }
        }),
        map((data) => this.markPinnedItems(data, requestParams.pinned)),
        shareReplay()
      );
    } else {
      return of({
        items: [], indicators: []
      });
    }
  }

  getBenchmarksItems(
    benchmarks: AnalysisBenchmarkModel[],
    stateConfig: AnalysisStateConfig,
    requestParams: RequestParamsModel,
  ) {
    const { datasetId, entityId, queryDataCollection } = stateConfig;
    const hasGroupPinned = !isUndefined(requestParams);
    const selectedBenchmarks = benchmarks.filter(bm => bm.enabled);
    if (this.isValidRequestForEntity(stateConfig.entityId, requestParams.indicators)) {
      if (selectedBenchmarks.length > 0) {
      return this.dataRepository.getBenchmarks(
        datasetId,
        entityId,
        assign(
          { benchmarkNames: selectedBenchmarks.map(bm => bm.name) },
          hasGroupPinned ? requestParams! : []
        ) as ReportTileRequestModel,
        queryDataCollection
      ).pipe(
        shareReplay()
      );
      } else {
        return of({} as ExploreTileResponseModel);
      }
    } else {
      return of({} as ExploreTileResponseModel);
    }

  }

  getGroupsItems(
    currentItems: Observable<ExploreTileResponseModel>,
    stateConfig: AnalysisStateConfig,
    requestParams: RequestParamsModel,
    url?: string
  ) {
    const { datasetId, entityId, queryDataCollection } = stateConfig;
    const hasGroupPinned = !isUndefined(requestParams.groupPinned);
    const oldExploreItems = requestParams.skip > 0 ? currentItems : undefined;
    if (this.isValidRequestForEntity(stateConfig.entityId, requestParams.indicators)) {
      return this.dataRepository.groups(
        datasetId,
        entityId,
        requestParams,
        queryDataCollection,
        url
      ).pipe(
        mergeMap((data) => {
          if (oldExploreItems) {
            return oldExploreItems.pipe(
              map((oldData) => assign(data, { items: (oldData.items || []).concat(data.items) }))
            );
          } else {
            return of(data);
          }
        }),
        map((data) => this.markPinnedItems(data, hasGroupPinned ? requestParams.groupPinned! : [])),
        shareReplay()
      );
    } else {
      return of({
        items: [], indicators: []
      });
    }
  }

  getTotal(
    stateConfig: AnalysisStateConfig,
    requestParams: RequestParamsModel,
  ) {
    const { datasetId, entityId, queryDataCollection } = stateConfig;

    return this.dataRepository.count(
      datasetId,
      entityId,
      requestParams,
      queryDataCollection
    );
  }

  markPinnedItems(data: ExploreTileResponseModel, pinned: string[]) {
    if (!data) {
      return {};
    }
    const items = data.items?.map((item) => {
      item.pinned = !isUndefined(pinned) && pinned.includes(item.key);

      return item;
    });
    return assign(data, { items });
  }

  getItemsTotal(
    stateConfig: AnalysisStateConfig,
    requestParams: RequestParamsModel
  ) {
    const { datasetId, entityId, queryDataCollection } = stateConfig;
    if (this.isValidRequestForEntity(stateConfig.entityId, requestParams.indicators)) {
    return this.dataRepository.count(datasetId, entityId, requestParams, queryDataCollection).pipe(
      map((response: any) => response !== 'undefined' ? response.totalItems : 0), // tslint:disable-line no-any
      shareReplay()
    );
    } else {
      return of({ totalItems: 0 });
    }
  }

  // tslint:disable-next-line no-any
  mapForDocsTotal(response: { items?: any[]; }) {
    if (!isUndefined(response.items) && 0 < response.items.length) {
      if (!isUndefined(response.items[0].value) && 0 < response.items[0].value.length) {
        return response.items[0].value[0].wosDocuments;
      }
    }
  }

  getDocsTotal(
    stateConfig: AnalysisStateConfig,
    requestParams: RequestParamsModel,
  ) {
    const { datasetId, entityId, queryDataCollection } = stateConfig;
    const request = assign({ indicators: ['key', 'wosDocuments'], benchmarkNames: ['all'] }, requestParams) as ReportTileRequestModel;
    if (this.isValidRequestForEntity(stateConfig.entityId, request.indicators)) {
      return this.dataRepository.getBenchmarks(datasetId, entityId, request, queryDataCollection).pipe(
        map(this.mapForDocsTotal),
        shareReplay()
      );
    } else {
      return of(0);
    }

  }

  cleanupStateIndicatorsOrBenchmarks(stateIndicatorsOrBenchmark: string[], allIndicatOrsOrBenchmarks: Indicator[] | BenchmarkModel[] | AnalysisIndicatorModel[]) {
    let index = stateIndicatorsOrBenchmark.length;
    if (index) {
      while (index--) {
        // @ts-ignore
        const item = allIndicatOrsOrBenchmarks.find(value => value.name === stateIndicatorsOrBenchmark[index]);
        if (!item || (item && item.hidden)) {
          stateIndicatorsOrBenchmark.splice(index, 1);
        }
      }
    }

    return stateIndicatorsOrBenchmark;
  }
  getIndicators(entity: string, state: SerializedExploreStateModel): Observable<AnalysisStateIndicatorsModel> {
    return this.metadataRepository.getIndicators(entity, state.datasetId).pipe(
      map((data: IndicatorResponseModel) => {
        const getIndicator = (prevIndicators: string[], currentIndicators: string[]) => {
          if (prevIndicators && prevIndicators.length) return prevIndicators;
          return currentIndicators;
        };
        const { primaryIndicator, primaryOrder } = data;
        let indicatorLength = state && state.request && state.request.indicators && state.request.indicators.length || 0;
        if (indicatorLength) {
          state.request.indicators = this.cleanupStateIndicatorsOrBenchmarks(state.request.indicators, data.indicators);
        }
        const previouslyEnabledIndicators = state && state.request && state.request.indicators;
        const enabledIndicators = getIndicator(previouslyEnabledIndicators, data.enabledIndicators);
        const indicators = this.indicatorService.getIndicators(data.indicators, enabledIndicators, primaryIndicator);
        const groups = this.indicatorService.getGroupedIndicators(indicators, data.indicatorGroups);
        const influencingFiltersNames = Array.from(indicators.reduce((acc, i) => {
          if (i.ctrlBy) {
            i.ctrlBy.forEach((str) => acc.add(str));
          }
          return acc;
        }, new Set<string>()));

        return { indicators, groups, primaryIndicator, primaryOrder, influencingFiltersNames };
      })
    );
  }

  cleanupStateFilters(stateFilters: { [index: string]: Partial<FullFilterValueModel> }, allFilters: FilterModel[] | AnalysisFilterModel[]) {
    Object.keys(stateFilters).forEach(key => {
      // @ts-ignore
      let filterObj = allFilters.find(filter => filter.name === key);
      if (!filterObj || (filterObj && filterObj.hidden)) {
        delete stateFilters[key];
      }
    });
    return stateFilters;
  }

  getFilters(config: AnalysisStateConfig, request: RequestParamsModel, tile?: ReportTileModel) {
    // TODO: move this to metadata
    const postProcessAssprsnId = (metadata: FiltersResponseModel) => {
      const affectedEntityIds = ['region', 'subject', 'journal'];
      const replaceMap: { [index: string]: {} } = {
        assprsnIdTypeGroup: { interDependent: ['personIdTypeGroup'], required: true },
        assprsnIdType: { dependsOn: ['assprsnIdTypeGroup'], interDependent: ['personIdType'] },
        personId: { dependsOn: ['assprsnIdType'] },
        personIdTypeGroup: { interDependent: ['assprsnIdTypeGroup'] },
        personIdType: { interDependent: ['assprsnIdType'] }
      };

      if (affectedEntityIds.includes(config.entityId)) {
        metadata.filters = metadata.filters?.map((filter) => {
          return replaceMap[filter.name] ? assign(filter, replaceMap[filter.name]) : filter;
        });
      }

      return metadata;
    };
    // TODO: move this to metadata
    const postProcessClbrprsnId = (metadata: FiltersResponseModel) => {
      const affectedEntityIds = ['person'];
      let replaceMap: { [index: string]: {} } = {};
      replaceMap = {
        clbrprsnIdTypeGroup: { noRequiredDeFaultValue: true },
        clbrprsnIdType: { dependsOn: ['clbrprsnIdTypeGroup'], noRequiredDeFaultValue: true },
        clbrprsnId: { dependsOn: ['clbrprsnIdType'] }
      };

      if (affectedEntityIds.includes(config.entityId)) {
        metadata.filters = metadata.filters.map((filter) => {
          return replaceMap[filter.name] ? assign(filter, replaceMap[filter.name]) : filter;
        });
      }

      return metadata;
    };
    // TODO: move this to metadata
    const postProcessDependent = (metadata: AnalysisStateFiltersModel) => {
      const dependendFltersList = metadata.filters.filter((fltr) => fltr.dependsOn && isArray(fltr.dependsOn));
      const dependsOnFilterNamesSet = dependendFltersList.reduce((acc, fltr) => {
        fltr.dependsOn!.forEach(acc.add, acc);
        return acc;
      }, new Set<string>());

      metadata.filters = metadata.filters.map((fltr) => {
        if (dependsOnFilterNamesSet.has(fltr.name)) {
          const dependent = dependendFltersList
            .filter((dfltr) => dfltr.dependsOn!.includes(fltr.name))
            .map((dfltr) => dfltr.name);

          fltr = assign(fltr, { dependent });
        }

        return fltr;
      });

      return metadata;
    };

    const updatePlaceholder = (metadata: AnalysisStateFiltersModel) => {
      metadata.filters = metadata.filters.map((filter) => {
        const { placeholder } = filter;
        if (isPlainObject(placeholder)) filter.searchPlaceHolder = { ...placeholder as { [key: string]: string } };
        return filter;
      });
      return metadata;
    };

    const removeCommonFiltersToShow = (metadata: AnalysisStateFiltersModel) => {
      const { filters } = metadata;
      for (const filter of filters) {
        if (!isArray(filter.source) || !filter.source.some((option: { filtersToShow: string[] }) => isPlainObject(option) && !!option.filtersToShow)) continue;
        const allFilterInFiltersToShow: Array<Array<string>> = [];
        const { source } = filter;
        for (const { filtersToShow } of source) {
          if (!isArray(filtersToShow) || !filtersToShow.length) continue;
          allFilterInFiltersToShow.push(filtersToShow);
        }
        const commonfilters = allFilterInFiltersToShow.length > 1 && intersection(...allFilterInFiltersToShow);
        if (commonfilters && commonfilters.length) {
          for (const { filtersToShow } of source) {
            if (!isArray(filtersToShow) || !filtersToShow.length) continue;
            for (const filterName of commonfilters) {
              filtersToShow.splice(filtersToShow.indexOf(filterName), 1);
            }
          }
        }
      }
      return metadata;
    };

    const postProcessFiltersToShow = (metadata: AnalysisStateFiltersModel) => {
      const { filters } = metadata;
      const filtersToHide = filters.reduce((acc, fltr) => {
        const isTrigger = isArray(fltr.options) && fltr.options.some((option) => {
          if (option.filtersToShow) return true;
          return false;
        });
        if (isTrigger) {
          const triggerName = fltr.name;
          const isCheckbox = fltr.type === 'boolean';
          fltr.options!.forEach(({ filtersToShow, value }) => {
            const triggerValue = value;
            if (filtersToShow) {
              filtersToShow.forEach((targetFilterName: string) => {
                if (acc[targetFilterName]) {
                  if (acc[targetFilterName][triggerName]) {
                    acc[targetFilterName][triggerName].is.push(triggerValue);
                  } else {
                    acc[targetFilterName][triggerName] = { is: [triggerValue], isCheckbox };
                  }
                  return;
                }
                acc[targetFilterName] = { [triggerName]: { is: [triggerValue], isCheckbox } };
              });
            }
          });
        }
        return acc;
      }, {} as { [index: string]: { [index: string]: { is: string[], isCheckbox: boolean } } });
      filters.forEach((fltr) => {
        if (fltr.name in filtersToHide) {
          fltr = assign(fltr, { showOn: filtersToHide[fltr.name] });
        }
        return fltr;
      });
      return metadata;
    };

    return this.metadataRepository.getFiltersRx(config.datasetId, config.entityId, config.filterAggId!).pipe(
      map((data) => this.filtersService.updateFilterGroups(data, config.entityId)),
      map(postProcessAssprsnId),
      map(postProcessClbrprsnId),
      mergeMap((metadata) => {
        const retainAnalysisOnDatasetSwitch = this.env.feature.retainAnalysisOnDatasetSwitch;
        const previousTileDatasetState = cloneDeep(this.userStorage.getPreviousDatasetState(config.datasetId, config.entityId));
        try {
          let stateValues = (tile && JSON.parse(tile.params).request && (!previousTileDatasetState || !retainAnalysisOnDatasetSwitch) ?
            JSON.parse(tile.params).request.filters : cloneDeep(request.filters)) || {};
          let filtersLength = stateValues && Object.keys(stateValues).length || 0;
          if (filtersLength) {
            stateValues = this.cleanupStateFilters(stateValues, metadata.filters.concat([metadata.search, metadata.period]));
          }

          const stateValueNames = Object.keys(stateValues);
          if (stateValueNames.includes('period')) {
            // @ts-ignore
            if (Object.keys(metadata).includes('period')) {
              metadata.period.appliedValues = stateValues.period;
            } else {
              // @ts-ignore
              metadata.period = { appliedValues: stateValues.period };
            }
          }

          return this.filtersService.getFiltersFromResponse(metadata, stateValues, config.entityId);
        } catch (error) {
          console.log(error);
          return new Observable() as unknown as Observable<AnalysisStateFiltersModel>;
        }
      }),
      map(postProcessDependent),
      map(updatePlaceholder),
      map(removeCommonFiltersToShow),
      map(postProcessFiltersToShow)
    );
  }


  getDatasets(config: AnalysisStateConfig, tile?: ReportTileModel) {
    const datasetId = tile && tile.datasetId || config.datasetId;
    const owner = tile && tile.owner || this.userService.getCurrentUser()!.name;

    return this.datasetsRepository.getListRx({ filterAggId: config.filterAggId! }).pipe(
      mergeMap((datasets) => {
        const selectedDataset = find(datasets, { datasetId });

        if (selectedDataset) {
          selectedDataset.selected = true;
          return of(datasets);
        }

        return this.datasetsRepository.getListRx({ datasetId, owner }).pipe(
          map((requiredDatasets) => datasets.concat(requiredDatasets)),
          map((requiredDatasets) => {
            const dataset = find(datasets, { datasetId });

            if (dataset) {
              dataset.selected = true;
            }

            return requiredDatasets;
          })
        );
      }),
    );
  }

  getBenchmarks(config: AnalysisStateConfig, state: SerializedExploreStateModel) {
    const processBenchmark = (benchmark: BenchmarkModel): AnalysisBenchmarkModel => {
      const enabled = includes(state.benchmarks, benchmark.name);
      return assign({ enabled }, benchmark);
    };

    return this.metadataRepository.getBenchmarks(config.datasetId, config.entityId, config.filterAggId).pipe(
      tap((benchmarks) => {
        const benchmarkLength = state.benchmarks && state.benchmarks.length;
        if (benchmarkLength) {
          state.benchmarks = this.cleanupStateIndicatorsOrBenchmarks(state.benchmarks, benchmarks);
        }
      }),
      map((benchmarks) => (benchmarks || []).map(processBenchmark)),
    );
  }

  getQueryDataCollection(): string | undefined {
    const currentUser = this.userService.getCurrentUser();
    const esciToBoolean = (esciStr: string) => !isUndefined(esciStr) && esciStr.toLowerCase() === 'true';
    if (currentUser) {
      return esciToBoolean(currentUser.userEsciFlag ? currentUser.userEsciFlag : currentUser.esciFlag) ? 'ESCI' : undefined;
    }

    throw new Error('User is not authorised');
  }

  getTile(config: AnalysisStateConfig) {
    return this.reportsRepository.get(config.reportId!).then((report) => {
      return find(report.tiles, { id: config.tileId });
    });
  }

  get mapsPOCS() {
    return {
      treemapPOC: this.env.feature.treemapPOC,
      amchartsPOC: this.env.feature.amchartsPOC,
      multiIndicatorTrendGraphPOC: this.env.feature.multiIndicatorTrendGraphPOC,
      trendGraphPOC: this.env.feature.trendGraphPOC,
      fiveYearTrendGraphPOC: this.env.feature.fiveYearTrendGraphPOC,
      barAmchartsPOC: this.env.feature.barAmchartsPOC,
      heatmapAmchartsPOC: this.env.feature.heatmapAmchartsPOC,
      pieAmchartsPOC: this.env.feature.pieAmchartsPOC,
    };
  }

  isCitationTopics(request: RequestParamsModel) {
    const filterKeys = Object.keys(request.filters);

    if (filterKeys.includes('schema')) {
      const { is } = request.filters['schema'];
      // @ts-ignore
      return is && is === 'Citation Topics';
    }
    return false;
  }

  isFilterMatched(request: RequestParamsModel, filterName: string, value: string ) {
    const filterKeys = Object.keys(request.filters);

    if (filterKeys.includes(filterName)) {
      const { is } = request.filters[filterName];
      // @ts-ignore
      return is && is === value;
    }
    return false;
  }

  isSchemaLevel(request: RequestParamsModel, match: string) {
    const filterKeys = Object.keys(request.filters);

    if (filterKeys.includes('schemalevel')) {
      const { is } = request.filters['schemalevel'];
      // @ts-ignore
      return is && is === match;
    }
    return match === 'Macro';
  }

  isSchemaLevelMacro(request: RequestParamsModel) {
    if (this.isSchemaLevel(request, 'Macro')) {
      return isUndefined(request.filters.sbjname);
    }
    return false;
  }

  isSchemaLevelMeso(request: RequestParamsModel) {
    if (this.isSchemaLevel(request, 'Meso')) {
      if (this.isSchemaLevelMacro(request)) {
        return !isUndefined(request.filters.sbjname);
      }
      return isUndefined(request.filters.sbjname);
    }
    if (this.isSchemaLevel(request, 'Macro')) {
      return !isUndefined(request.filters.sbjname);
    }
    return false;
  }

  isSchemaLevelMicro(request: RequestParamsModel) {
    if (this.isSchemaLevel(request, 'Micro')) {
      return true;
    }
    if (this.isSchemaLevel(request, 'Meso')) {
      return !isUndefined(request.filters.sbjname);
    }
    return false;
  }

  isChineseUser() {
    return this.userService.getCurrentUser()!.customerCountry.toLowerCase().includes('china');
  }

  omitVisualization(request: RequestParamsModel, visualization: VisualizationModel) {
    if (visualization.type === 'map amCharts' ||
      visualization.type === 'map chart') {
      return !this.isChineseUser();
    }
    if (visualization.type !== 'tree heatmap' && visualization.type !== 'wordCloud graph') return true;
    const filterKeys = Object.keys(request.filters);
    if (filterKeys.includes('schema')) {
      const { is } = request.filters['schema'];
      // @ts-ignore
      return is && is === 'Citation Topics';
    }
  }

  setDefaultVisualisation(visualizations: VisualizationModel[]) {
    const barchartIndex = visualizations.findIndex((visualization => visualization.type === 'bar chart'));
    visualizations[barchartIndex].default = true;
    return visualizations;
  }

  getCleanConfig(config: AnalysisStateConfig) {
    let configCopy = cloneDeep(config);

    if (!this.env.analysis.visualization.disableChooseDifferentVisualization.includes(configCopy.filterAggId)) {
      configCopy.filterAggId = undefined;
    }
    return configCopy;
  }

  getVisualizations(config: AnalysisStateConfig, request: RequestParamsModel) {
    const addamChartsVisualizations = (visualizations: VisualizationModel[]) => {
      const addVisualizations = (oldType: DiagramLongType, newType: DiagramLongType, checkDefault = true, title?: string) => {
        const oldVisuasl = visualizations.find((vis) => vis.type === oldType);
        const visualConfig = assign({}, oldVisuasl);
        if (visualConfig && Object.keys(visualConfig).length > 0) {
          visualConfig.type = newType;
          if (oldVisuasl!.default && checkDefault) {
            visualConfig.default = true;
            oldVisuasl!.default = false;
          }
          if (title) {
            visualConfig.title = title;
          }
          visualizations.push(visualConfig);
        }
      };

      if (this.mapsPOCS.amchartsPOC) {
        addVisualizations('map chart', 'map amCharts');
      }

      if (this.mapsPOCS.multiIndicatorTrendGraphPOC) {
        addVisualizations('multiIndicatorLine chart', 'multiIndicatorLine amCharts');
      }

      if (this.mapsPOCS.trendGraphPOC) {
        addVisualizations('line chart', 'line amCharts');
      }

      if (this.mapsPOCS.barAmchartsPOC) {
        addVisualizations('bar chart', 'bar amCharts');
      }

      if (this.mapsPOCS.heatmapAmchartsPOC) {
        addVisualizations('heat map', 'heatmap amCharts');
      }

      if (this.mapsPOCS.fiveYearTrendGraphPOC) {
        addVisualizations('period chart', 'period amCharts');
      }
      if (this.mapsPOCS.pieAmchartsPOC) {
        // @ts-ignore
        addVisualizations('pie', 'pie amCharts');
      }

      if (this.mapsPOCS.treemapPOC) {
        addVisualizations('tree map', 'treemap amCharts');
        addVisualizations('treemap amCharts', 'treemap amchartsSDG', false, 'Treemap SDG');
      }
      return visualizations;
    };

    return this.metadataRepository.getVisualizations(
      config.datasetId,
      config.entityId,
      request.indicators,
      Object.keys(request.filters),
      this.getCleanConfig(config).filterAggId
    ).pipe(
      map((visualizations) => visualizations.filter((visualization) => this.omitVisualization(request, visualization))),
      map((visualizations) => (config.entityId === 'organization' && this.isChineseUser()) ? this.setDefaultVisualisation(visualizations) : visualizations),
      map((visualizations) => addamChartsVisualizations(visualizations)),
      shareReplay()
    );
  }

  setCurrentVis(vis: string) {
    this.currentVis.next(vis);
  }
  isValidRequestForEntity(entityId: string, indicators: string[]) {
    const entityName = this.analysisEntityService.getNamePropForEntityId(entityId);
    return indicators.includes(entityName);

  }
}
