<cl-popover-modal [options]="{modalWidth: '313px'}">
  <cl-popover-modal-button [buttonClass]="'cl-btn cl-btn--secondary cl-btn--with-icon'">
    <cl-icon [iconId]="'download'"></cl-icon>
    <span class="cl-btn__text">{{ 'analysis.row details.Download table' | translate }}</span>
    <cl-icon [iconId]="'arrow-down'" [color]="'color-gray40'"></cl-icon>
  </cl-popover-modal-button>
  <cl-popover-modal-content [modalTitle]="'analysis.row details.Download table' | translate">
    <form class="cl-form" [formGroup]="downloadForm" (ngSubmit)="onDownloadSubmit()">
      <fieldset class="cl-form__fieldset cl-form__fieldset--stacked">
        <div class="cl-form__field">
          <label class="cl-form-label" for="file-name">
            {{ 'analysis.row details.File name' | translate }}
          </label>
            <input id="file-name" class="cl-form-text" formControlName="fileName" type="text"
              placeholder="Web of Science documents" />
            </div>
            <div class="cl-form__field-group cl-form__field-group--three">
              <div class="cl-form__field">
                <label class="cl-form-label" for="records">
                  {{ 'analysis.row details.Records' | translate }}
                </label>
                <input id="records" class="cl-form-text" formControlName="records" type="number" min="1"
                  max="{{ maxRecordsValue }}">
              </div>
            </div>
            <div class="cl-form__actions">
            <select class="ic-select" formControlName="downloadOption" *ngIf="!isReportDrilldown">
              <option *ngFor="let type of types">{{type}}</option>
            </select>
            <button [disabled]="!downloadForm.valid" type="submit" class="cl-btn cl-btn--primary">
              {{ (!isReportDrilldown ?'analysis.row details.Download':'analysis.row details.Download csv') | translate }}
            </button>
            </div>
            </fieldset>
            </form>
            </cl-popover-modal-content>
            </cl-popover-modal>




