import { Component, OnInit, ViewEncapsulation, Inject, LOCALE_ID } from '@angular/core';
import { DatasetModel } from 'components/rest/services/datasets-repository/models';
import { DatasetsRepositoryService } from 'components/rest/services/datasets-repository/datasets-repository.service';
import { find, findKey, partial, isEqual } from 'lodash';
import { LocaleService } from '../../../../common/services/locale/locale.service';
import { ReportModel, ReportTypes } from 'components/common/interfaces/report.model';
import { ReportsRepositoryService } from 'components/rest/services/reports-repository/reports-repository.service';
import {StateService, UIRouter} from '@uirouter/core';
import { TabReportModel } from 'pages/tab-report/interfaces/tab-report.model';
import { TranslateService } from '@ngx-translate/core';
import { UserModel } from 'components/auth/interfaces/user.model';
import { UserService } from 'components/auth/services/user/user.service';
import { UserStorageService } from '../../../services/user-storage/user-storage.service';
import { NavigationService } from 'components/navigation/services/navigation/navigation.service';
import { DataRepositoryService } from 'components/rest/services/data-repository/data-repository.service';
import { SettingsRepositoryService } from 'components/rest/services/settings-repository/settings-repository.service';
import { Subscription } from 'rxjs';
import { isUndefined } from 'angular';
import * as pubsub from 'pubsub-js';


export interface NavigationDataModel {
  datasets: DatasetModel[];
  entities: {title: string, href: string, iconId: string}[];
  reports: ReportModel[];
  tabReports: TabReportModel[];
  user: UserModel;
}

@Component({
  selector: 'ic-global-nav',
  templateUrl: './global-nav.component.html',
  encapsulation: ViewEncapsulation.None
})
export class GlobalNavComponent implements OnInit {
  isAuthenticated: boolean = false;
  rootState: string = 'app';
  items: any = []; // tslint:disable-line
  mySaved: string = 'app.my-saved';
  dashboard = {
    href: `${this.rootState}.reports`,
    params: {
      reportId: 'dashboard'
    }
  };

  selectedMenu: string = '';
  listResponse: any; // tslint:disable-line no-any
  datasets: DatasetModel[];
  reports: ReportModel[];
  tabReports: TabReportModel[];
  user: UserModel;
  subscription = new Subscription();

  constructor(
    @Inject(LOCALE_ID) private localeId: string,
    private datasetsRepositoryService: DatasetsRepositoryService,
    private localeService: LocaleService,
    private reportsRepositoryService: ReportsRepositoryService,
    private stateService: StateService,
    private userService: UserService,
    private userStorageService: UserStorageService,
    public translate: TranslateService,
    private navigationService: NavigationService,
    private dataRepositoryService: DataRepositoryService,
    private settingsRepositoryService: SettingsRepositoryService,
    router: UIRouter  ) {

    router.transitionService.onStart({}, transition => {
      const to = transition.to(); // The state that was just activated
      if (to.name) {
        this.selectedMenu = to.name;
      }
    });
    this.settingsRepositoryService.listEntities.subscribe((data) => {
      this.items = this.dataRepositoryService.getEntities(data, '').toPromise().then((listResponse) => {
        listResponse.forEach((item) => {
          // @ts-ignore
          if (item.title === this.translate.instant(`analytics.entity.Departments`)) {
            // @ts-ignore
            // This may have to changed when Departments supports custom dataset
            item.href = '#/analysis/0/department';
          }
        });
        let responseObj = {datasets: this.datasets, entities: listResponse, reports: this.reports, tabReports: this.tabReports, user: this.user};
        return this.buildNavigationItems(responseObj);
      }).then((globalNavigation) => {
        const [ analysis, reports ] = globalNavigation;
        // @ts-ignore
        const { children: [ tabReport ] } = reports;
        this.navigationService.setAnalysisAndReportNav([ analysis, { ...reports, children: [tabReport] }]);
        return globalNavigation;
      });
    });
  }

  ngOnInit() {
    pubsub.subscribe('userAuthenticated', (_channel: string, _user: UserModel) => {
      if (!isUndefined(this.userService.currentUser)) {
          this.setItems();
      }
    });
  }

  setItems() {
    this.items = Promise.all([
      this.datasetsRepositoryService.getList({ datasetId: '', owner: '' }),
      this.dataRepositoryService.getEntities('', '').toPromise(),
      this.reportsRepositoryService.getList({ group: 'system' }),
      this.reportsRepositoryService.getTabReportsList().toPromise(),
      this.userService.getCurrentUserPromise(),

    ]).then((result) => {
      const [datasets, entities, reports, tabReports, user] = result;
      this.listResponse = entities;
      this.datasets = datasets;
      this.reports = reports;
      this.tabReports = tabReports;
      this.user = user;
      return this.buildNavigationItems({datasets, entities, reports, tabReports, user});
    }).then((globalNavigation) => {
      const [ analysis, reports ] = globalNavigation;
      // @ts-ignore
      const { children: [ tabReport ] } = reports;
      this.navigationService.setAnalysisAndReportNav([ analysis, { ...reports, children: [tabReport] }]);
      this.isAuthenticated = true;
      return globalNavigation;
    })
    .catch((error: Error) => console.error(error));
  }

  getInitialDataset(datasets: DatasetModel[]): DatasetModel | undefined {
    let lastUsedDatasetId = this.userStorageService.getLastUsedDatasetId();
    let dataset = find(datasets, { datasetId: lastUsedDatasetId });

    // @ts-ignore
    return dataset || (find(datasets, 'default') as DatasetModel | undefined);
  }

  async buildNavigationItems(data: NavigationDataModel) {
    const currentDataset: DatasetModel | undefined = this.getInitialDataset(data.datasets);
    const redirectParams = `?lc=${this.localeId}`;

    let datasetId;

    if (currentDataset !== undefined) {
      datasetId = currentDataset.datasetId;
    }

    if (data && data.entities) {
      this.listResponse = data.entities;
    }
    if (currentDataset !== undefined && datasetId !== '0') {
      this.listResponse = this.dataRepositoryService.getEntities(datasetId as string, '').toPromise();
    }

    const analysisSection = {
      title: this.translate.instant('nav.Analysis'),
      description: this.translate.instant('nav.Select entity'),
      stateName: 'app.analysis',
      children: [
        {
          heading: this.translate.instant('nav.Analyze by'),
          children: this.listResponse
        }
      ]
    };

    const reportsSection = {
      title: this.translate.instant('nav.Reports'),
      stateName: 'app.tabreport',
      description: this.translate.instant('nav.Visualize research'),
      children: await this.buildReportSubsectionChildren(data)
    };

    const myFoldersSection = {
      title: this.translate.instant('nav.Organize'),
      description: this.translate.instant('nav.Visualize research'),
      children: await this.buildOrganizeSubsectionChildren(),
      stateName: 'app.reports|app.my-saved',
    };

    const myOrgSection = {
      title: this.translate.instant('nav.My Organization'),
      stateName: 'My Organization',
      href: `/incites-app/redirect/myorg${redirectParams}`
    };

    const researchFrontsSection = {
      title: 'Research Horizon Navigator™',
      stateName: 'Research Fronts Navigator',
      href: `/incites-app/redirect/rhn`,
      target: '_blank'
    };

    const returnVal = [analysisSection, reportsSection, myFoldersSection, myOrgSection, researchFrontsSection];
    return returnVal;
  }

  private buildTabReportsLinks(reports: TabReportModel[]) {
    if (!reports) return [];

    return reports.reduce((items: Object[], report: TabReportModel) => {
      if (!report.disableMessage) {
        let reportName = findKey(Object.freeze(ReportTypes), partial(isEqual, report.type));
        reportName = reportName === 'researcherTabRpt' ? 'researcher' : reportName;
        items.push({
          title: report.title,
          href: this.localeService.href(`#/tabreports/${report.id}/`),
          iconId: reportName ? `${reportName}-report` : ''
        });
      }

      return items;
    }, []
  );
  }

  private async buildReportSubsectionChildren(data: any) { // tslint:disable-line
    const reportsLinks = await this.buildTabReportsLinks(data.tabReports);
    const children = [{heading: this.translate.instant('analytics.landing.Overview Reports'), children: reportsLinks}];
    return children;
  }

  private async buildOrganizeSubsectionChildren() {
    const organizeLinks = [
      {
        title: this.translate.instant('nav.My Folders'),
        href: this.localeService.href(`#/my-saved`)
      },
      {
        title: this.translate.instant('nav.Dashboard'),
        href: this.localeService.href(this.stateService.href(this.dashboard.href, this.dashboard.params))
      }
    ];
    const children = [{heading: this.translate.instant('nav.Organize by'), children: organizeLinks}];
    return children;
  }

}