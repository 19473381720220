import { Component, ViewEncapsulation, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OverlayModalComponent } from '@ic/component-lib/src/components/modules/overlay-modal/overlay-modal.component';
import { EventsTrackerService } from 'components/common/services/events-tracker/events-tracker.service';
import { ReportModel } from 'components/common/interfaces/report.model';
import { ReportTileModel } from 'components/common/interfaces/report-tile.model';
import { ReportsRepositoryService } from 'components/rest/services/reports-repository/reports-repository.service';
import { OverbarService } from 'components/overbar/services/overbar/overbar.service';

@Component({
  selector: 'ic-tile-remove',
  templateUrl: './tile-remove.component.html',
  encapsulation: ViewEncapsulation.None
})
export class TileRemoveComponent {
 
  @Input() report: Partial<ReportModel> = {};
  @Input() tile: Partial<ReportTileModel> = {};
  @ViewChild(OverlayModalComponent, { static: true }) overlayModal!: OverlayModalComponent;
  @Output() uncheckTile = new EventEmitter();
  constructor(
    public translate: TranslateService,
    private reportsRepositoryService: ReportsRepositoryService,
    private tracker: EventsTrackerService,
    private overbar: OverbarService
  ) {}

  openModal() {
    this.overlayModal.openModal();
  }

  closeModal() {
    this.overlayModal.closeModal();
  }

  delete() {
    this.uncheckTile.emit();
    this.report.tiles = this.report.tiles!.filter((tile) => this.tile.id !== tile.id );
    this.report.editSubTile = true;
    this.reportsRepositoryService.save(<ReportModel> this.report)
    .then(() => {
      this.closeModal();
      const { dashboard, title } = this.report;
      const overbarMessage = dashboard ? this.translate.instant('notifications.Dashboard Saved') : this.translate.instant('notifications.Report Saved', { report: title });
      this.overbar.notify(overbarMessage);
      this.tracker.trackPageView();
    });
    
  }
}