export const environmentProdConfig = {
  showDebugLocales: false,
  feature: {
    amchartsPOC: true,
    explorerTour: false,
    geoMapZoom: false,
    pasteFilterValues: true,
    tooltipsGuide: true,
    unlockRank: true,
    treemapPOC: true,
    multiIndicatorTrendGraphPOC: true,
    trendGraphPOC: true,
    fiveYearTrendGraphPOC: true,
    retainAnalysisOnDatasetSwitch: true,
    wordCloudPOC: true,
    barAmchartsPOC: true,
    heatmapAmchartsPOC: true,
    cdxHeaderFooter: true,
    pieAmchartsPOC: true,
    groups: true,
    checkboxBulk: true,
    moveBulkButton: true,
    cookieBannerContext: true,
    shareGroup : false
  },
  oneTrustKey: '2a3b1117-4fdb-4437-b969-55c32b810c34',
  pasteFilterValues: {
    issn: true,
    people: {
      assprsnIdType: ['allUniqueId', 'orcId', 'researcherId'],
      clbrprsnIdType: ['allUniqueId', 'orcId', 'researcherId'],
      personIdType: ['allUniqueId', 'orcId', 'researcherId'],
      singlePrsnIdType: ['allUniqueId', 'orcId', 'researcherId'],
    },
  },
  splitioKey: 'ns31c5sa1dviop174lr9c4a6dh997r6rkdlj',
  wos: 'https://apps.webofknowledge.com/',
  gatewayLink: 'https://gateway.webofknowledge.com/gateway/Gateway.cgi',
  derwentGatewayLink: 'https://www.webofscience.com/api/gateway',
  cookieBannerScriptId: '2a3b1117-4fdb-4437-b969-55c32b810c34',
  rhnHost : 'https://research-horizon-navigator.incites.clarivate.com'
};