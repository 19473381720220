import { extend, assign, has, isUndefined } from 'lodash';
import { FriendlyUrlService } from '../../../common/services/friendly-url/friendly-url.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, shareReplay, catchError, mergeMap } from 'rxjs/operators';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { ReportModel, InstitutionReportModel, OrgInfo, ReportTileParamsModel } from 'components/common/interfaces/report.model';
import { TabReportModel } from 'pages/tab-report/interfaces/tab-report.model';
import { TileService } from 'components/rest/services/tile/tile.service';
import { UserService } from 'components/auth/services/user/user.service';
import { utilities } from 'angularjs/utilities/utilities';
import {RequestParamsModel} from 'pages/analysis/models/analysis-state.model';
import { environmentCommonConfig } from 'components/app/services/environment/environment-common.config';
import {DocumentModel, DocumentsColumnModel} from 'pages/analysis/models';
import {SortableTableColumnModel, SortableTableSortDirection} from '@ic/component-lib/src/components/modules/sortable-table/models';
import {SortableTableRowModel} from 'components/modules/sortable-table/models';
import {FormatterService} from 'components/ui/filters/formatter/formatter.service';
import { EnvironmentService } from 'components/app/services/environment/environment.service';
import { Globals } from 'components/shared/globalData';

const baseUrl: string = '/incites-app/';

@Injectable()
export class ReportsRepositoryService {

  private serviceUrl: string = `${baseUrl}reports/`;
  private url: string = `${baseUrl}share/report/`;
  private tabReportsList$!: Observable<TabReportModel[]>;
  
  env = this.environmentService.getEnvironment();
 
  toggleSubscription: Subscription;

  constructor(
    private friendlyUrl: FriendlyUrlService,
    private http: HttpClient,
    private tileService: TileService,
    private userService: UserService,
    private formatter: FormatterService,
    private environmentService: EnvironmentService,
    public globalData: Globals,
  ) {
  }

  extendReport(report: ReportModel) {
    if (report) {
      report.safeTitle = () => this.friendlyUrl.sanitize(report.title);
      report.isShared = () => !report.system && !this.userService.isCurrentUser(report.owner);
      report.isOwners = () => !report.system && !report.isShared();
      if (report.tiles) {
        report.tiles = report.tiles.map(tile => {
          return {
            ...tile,
            safeTitle: () => this.friendlyUrl.sanitize(<string>tile.title)
          };
        });
      } else {
        report.tiles = [];
      }
    }
    return report;
  }

  getFilterOptions(url: string, text?: string, type?: { [index: string]: string; } | { personIdType: string | undefined; }): Observable<string[]> {
    let params;

    if (text) {
      if (!isUndefined(type)) {
        if (type.hasOwnProperty('personIdType') && !isUndefined(type.personIdType)) {
          params = { params: new HttpParams({ fromObject: extend({ q: text }, type as { [index: string]: string; } | { personIdType: string; }) }) };
        } else {
          params = { params: new HttpParams({ fromObject: extend({ q: text }, type as { [index: string]: string; }) }) };
        }
      } else {
        params = { params: new HttpParams({ fromObject: { q: text } }) };
      }
    }

    return this.http.get<string[]>(url, params);
  }

  getOrganizationsInfo(organizations: string[]): Observable<OrgInfo[]> {
    return this.http.post<OrgInfo[]>(`${this.serviceUrl}institution/info`, { organizations });
  }

  getOrganizationInfo(organization: string): Observable<OrgInfo> {
    return this.getOrganizationsInfo([organization]).pipe(map(info => info[0]));
  }

  rxGetReportsList(params: { [param: string]: string | string[] }): Observable<ReportModel[]> {
    const httpParams = new HttpParams({ fromObject: params });

    return this.http.get<ReportModel[]>(this.serviceUrl, { params: httpParams })
      .pipe(
        map((reports) => reports.map(report => this.extendReport(report)))
      );
  }

  /**
   * Get a list of reports.
   * @param  {Object} params - The parameters to filter reports.
   * @param  {String} params.group - The group of the report (e.g. system, user).
   * @param  {String} params.sharedWithMe - Specifies whether the report is shared with the current user or not.
   * @return {Promise} - The list of reports.
   */
  getList(params: { [param: string]: string | string[] }): Promise<ReportModel[]> {
    return this.rxGetReportsList(params).toPromise();
  }

  getTabReportFilters(tabReportId: string): Observable<any> { // tslint:disable-line: no-any
    return this.http.get<any>( // tslint:disable-line: no-any
      `${baseUrl}reports/tabReports/${tabReportId}/metadata/filters`
    );
  }

  getTabReportsList(): Observable<TabReportModel[]> {
    if (!this.tabReportsList$) {
      this.tabReportsList$ = this.http.get<TabReportModel[]>(`${this.serviceUrl}tabReports`).pipe(
        map(tabReports => tabReports.map(tabReport => extend(tabReport, { newExpLabel: true }))),
        shareReplay()
      );
    }

    return this.tabReportsList$;
  }

  rxGet(id: number | string, params?: { [index: string]: string }): Observable<ReportModel> {
    const httpParams = new HttpParams({ fromObject: params });

    return this.http.get<ReportModel>(`${this.serviceUrl}${id}`, { params: httpParams })
      .pipe(
        map((report) => this.extendReport(report)),
        map((report) => this.assignTilesData(report)),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  get(id: number | string, params?: { [index: string]: string }): Promise<ReportModel> {
    return this.rxGet(id, params).toPromise();
  }

  getInstitutionReport(institution: string): Promise<InstitutionReportModel> {
    return this.http.get<InstitutionReportModel>(`${this.serviceUrl}institution/${institution}`)
      .pipe(
        mergeMap((report) => this.getOrganizationInfo(institution).pipe(
          map(info => assign(report, { info }))
        )),
        map((report) => assign(report, { report: this.extendReport(report.report) })),
        map((report) => assign(report, { report: this.assignTilesData(report.report) })),
        map((report) => this.friendlyUrl.sanitizeInstitutionReportUrl(report))
      )
      .toPromise();
  }

  create(report: Partial<ReportModel>): Promise<ReportModel> {
    return this.http.post<ReportModel>(this.serviceUrl, report)
      .pipe(map((savedReport) => this.extendReport(savedReport)))
      .toPromise();
  }

  rxSave(report: ReportModel): Observable<ReportModel> {
    return this.http.post<ReportModel>(`${this.serviceUrl}${report.id}`, report).pipe(
      map((savedReport) => this.extendReport(savedReport))
    );
  }

  save(report: ReportModel): Promise<ReportModel> {
    return this.rxSave(report).toPromise();
  }

  /**
   * Crate a copy of a report.
   * @param   {Number} reportId - The report id.
   * @param   {Object} params - The parameters to create a copy of a tile.
   * @param   {String} params.title - The report title.
   * @param   {Number} params.folderId - The destination folder id.
   * @returns {Promise} - The copy of the report.
   */
  clone(report: Partial<ReportModel> | Partial<TabReportModel>, params: {}): Promise<ReportModel> {
    const reportsUrl = has(report, 'reports') ? `${this.serviceUrl}tabReports/` : `${this.serviceUrl}`;

    return this.http.post<ReportModel>(`${reportsUrl}${report.id}/clone`, params)
      .pipe(map((rprt) => this.extendReport(rprt)))
      .toPromise();
  }

  delete(reportId: string): Promise<any> { // tslint:disable-line:no-any
    return this.http.post(`${this.serviceUrl}delete/${reportId}`, {})
      .toPromise();
  }

  drilldown(reportId: number | string, tileId: number) {
    return this.http.get(`${this.serviceUrl}${reportId}/tiles/${tileId}/drilldown`)
      .toPromise();
  }

  getDrilldownData(url: string, params: {}, request?: Partial<RequestParamsModel>) {
    if (request && !request.pinned) {
      request.pinned = [];
    }
    const httpParams = { params: new HttpParams({ fromObject: params }) };
    if (request && request.filters && request.filters.jrnkey) {
      delete request.filters['jrnkey'];
    }
    return utilities.isDefined(request) ? this.http.post(url, request, httpParams) : this.http.get(url, httpParams);
  }

  getTileDrilldownData(params: { [index: string]: string | number }) {
    params = params || {};

    const queryParams = {
      skip: params.skip,
      take: params.take,
      sortOrder: params.sortOrder,
      sortBy: params.sortBy
    };
    const httpParams = new HttpParams({ fromObject: queryParams });

    return this.http.get(
      `${this.serviceUrl}${params.reportId}/tiles/${params.tileId}/drilldown/data`,
      { params: httpParams }
    )
      .toPromise();
  }

  getTile(reportId: string, tileId: string) {
    return this.http.get(`${this.serviceUrl}${reportId}/tiles/${tileId}`)
      .toPromise();
  }

  createTile(reportId: string, params: {}) {
    return this.http.post(`${this.serviceUrl}${reportId}/tiles`, params)
      .toPromise();
  }

  /**
   * Create a copy of a tile.
   * @param   {Object} params - The parameters to create a copy of a tile.
   * @param   {Number} params.srcReportId - The source report id.
   * @param   {Number} params.srcTileId - The source tile id.
   * @param   {Number} params.dstReportId - The destination report id.
   * @param   {String} params.dstTileTitle - The destination tile title.
   * @param   {String} params.dstTileSubtitle - The destination tile subtitle.
   * @param   {String} params.params - The tile parameters.
   * @returns {Promise} - The copy of the tile.
   */
  cloneTile(params: { [index: string]: string }): Promise<any> { // tslint:disable-line:no-any
    params = params || {};

    const putData: { [index: string]: string } = {
      reportId: params.dstReportId
    };

    if (params.dstTileTitle) {
      putData.tileTitle = params.dstTileTitle;
    }
    if (params.dstTileSubtitle) {
      putData.tileSubtitle = params.dstTileSubtitle;
    } else {
      putData.tileSubtitle = '';
    }

    if (params.params) {
      putData.params = params.params;
    }

    return this.http.post(
      `${this.serviceUrl}${params.srcReportId}/tiles/${params.srcTileId}/clone`,
      putData
    )
      .toPromise();
  }

  share(reportId: number | string, params: {}): Observable<boolean> {
    const url = `${this.url}${reportId}`;
    return this.http.post<ReportModel>(url, params)
      .pipe(
        map(() => true),
        catchError((err: string) => {
          console.error(err);
          return of(false);
        })
      );
  }

  private assignTilesData(report: ReportModel) {
    report.tiles = report.tiles.map(
      (tile) => {
        tile.params = this.mapFilters(tile.params);
        if (tile.subscribedCountUrl) {
          try {
            tile.subscribedCount = this.tileService.getAlmaCount(JSON.parse(tile.subscribedCountParams as string) as ReportTileParamsModel, tile.subscribedCountUrl);
          } catch (error) {
            console.log(error);
          }
        }

        return assign(tile, { data$: this.tileService.getValues(tile) });
      }
    );

    return report;
  }

  mapFilters(params: string) {
    try {
      let paramsObj = JSON.parse(params);
      let {request} = paramsObj;
      if (request && request.filters && request.filters.jrnSourceType) {
        Object.keys(environmentCommonConfig.filterMapping.jrnSourceType).forEach((key) => {
          if (request.filters.jrnSourceType.is.includes(key)) {
            // @ts-ignore
            request.filters.jrnSourceType.is = environmentCommonConfig.filterMapping.jrnSourceType[key];
          }
        });
      }

      paramsObj.request = request;
      return JSON.stringify(paramsObj);
    } catch (e) {
      return params;
    }
  }

  buildColumnData(columns: DocumentsColumnModel[], currentSort:  {sortBy: string | undefined, sortOrder: 'asc' | 'desc' | undefined}): SortableTableColumnModel[] {
    return columns.map(column => {
      return {
        ...column,
        id: column.name,
        isDefaultSort: column.name === currentSort.sortBy,
        defaultSortOrder: <SortableTableSortDirection>currentSort.sortOrder,
        sortable: true,
      };
    });
  }

  buildRowData(columns: SortableTableColumnModel[], items: DocumentModel[]): SortableTableRowModel[] {
    const formatDocData = (item: DocumentModel) => columns.map(column => formatDocDataByColumn(column, item));
    const formatDocDataByColumn = (column: SortableTableColumnModel, item: DocumentModel) => {
      const columnValue = item[column.id];
      const columnId = column.id;

      if (columnValue !== null && typeof columnValue === 'object') {
        return { columnId, value: columnValue.title, linkHref: columnValue.url };
      }

      return { columnId, value: columnValue, valueFormatted: this.formatter.format(columnValue, column) };
    };

    return items.map((item, index) => ({
      ...item,
      id: `document-${index}`,
      index: index + 1,
      data: formatDocData(item)
    }));
  }
}