import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractFilterComponent } from '@ic/component-lib/src/components/modules/analysis-filters/components/abstract-filter/abstract-filter.component';
import {
  MultiSelectOptionModel
} from '@ic/component-lib/src/components/modules/multi-select/models';
import { OverlayModalComponent } from '@ic/component-lib/src/components/modules/overlay-modal/overlay-modal.component';
import { AnalysisFiltersService } from 'components/common/services/analysis-filters/analysis-filters.service';
import {cloneDeep, isArray, isUndefined, without, find} from 'lodash';
import { AnalysisStateModel } from 'pages/analysis/models/analysis-state.model';
import {FilterValueModel, FilterValuesSelectionModel} from 'pages/analysis/models/edit-filter-items.model';
import { FilterOptionModel } from 'pages/tab-report/interfaces';
import { TabReportService } from 'pages/tab-report/services/tab-report/tab-report.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { MultiSelectComponent } from '@ic/component-lib/src/components/modules/multi-select/multi-select.component';
import { AppliedFilterValuesModel } from '@ic/component-lib/src/components/modules/applied-filters/models';
import { SearchViewOptionModel, TreeViewOptionModel } from '@ic/component-lib/src/components/modules/tree-view/models';


import { ChooseDepartmentService } from 'components/common/services/choose-department/choose-department.service';
import { DepartmentModel } from 'components/common/services/choose-department/models';
import { AnalysisFilterModel as PageAnalysisFilterModel } from 'pages/analysis/models';
import { debounceTime, finalize } from 'rxjs/operators';
import { TreeViewComponent } from '@ic/component-lib/src/components/modules/tree-view/tree-view.component';
import {SettingsRepositoryService} from 'components/rest/services/settings-repository/settings-repository.service';
import { AnalysisService } from 'pages/analysis/services/analysis-service/analysis.service';


interface HeaderFilterConfig {
  type?: {
    hidden?: boolean;
    name: string;
    label?: string;
    options: FilterOptionModel[];
    value: string;
    onChange: (value: string) => void;
  };
  subType?: {
    hidden?: boolean;
    name: string;
    label?: string;
    options: FilterOptionModel[];
    value: string;
    url: string;
    onChange: (value: string) => void;
  };
  childSubtype?: {
    hidden?: boolean;
    name: string;
    value: string[];
  };
  search: {
    hidden?: boolean;
    name: string;
    filteredDataFound?: string | boolean;
    inputValue?: string;
    label?: string;
    onInputChange: (val: MultiSelectOptionModel) => void;
    onPastedChange: (val: MultiSelectOptionModel) => void;
    options$: Observable<FilterOptionModel[]>;
    placeholder?: string | { [key: string]: string };
    prefetch?: boolean;
    url: string;
    values: FilterOptionModel[];
    dependsOn?: string[];
    searchPlaceHolder?: { [key: string]: string };
  };
}

@Component({
  selector: 'ic-choose-tree-modal',
  templateUrl: './choose-tree-modal.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ChooseTreeModalComponent extends AbstractFilterComponent implements OnChanges, OnInit {
  @ViewChild(OverlayModalComponent, { static: true }) overlayModal!: OverlayModalComponent;
  @ViewChild(MultiSelectComponent, { static: false }) multiSelectComponent!: MultiSelectComponent;
  @ViewChild(TreeViewComponent, { static: false }) treeViewComponent!: TreeViewComponent;
  @HostBinding('class.cl-navigation__item--secondary') getSelectedFindingDataSource!: string;
  @Input() state!: AnalysisStateModel;
  // tslint:disable-next-line:no-any
  @Input() sidebarFilters: any;
  // tslint:disable-next-line:no-any
  @Input() reports: any;
  @Input() filtersConfig: HeaderFilterConfig = {} as HeaderFilterConfig;
// tslint:disable-next-line:no-any
  @Input() allFilters: any;
  @Input() schema: { [filter: string]: AppliedFilterValuesModel } = {};
  @Output() filterApply = new EventEmitter<{ [filter: string]: AppliedFilterValuesModel }>();
  @Output() onReportsFilterUpdate = new EventEmitter();
  @Output() removePinned = new EventEmitter();

  @Input() labels!: { [key: string]: string; };

  @Input() values: MultiSelectOptionModel[] = [];

  @Output() update = new EventEmitter();
  @ViewChild('searchinput') redel: any; // tslint:disable-line:no-any
  private searchDepartments: Subject<string> = new Subject<string>();
  searchResponse: SearchViewOptionModel[];
  searching: boolean = false;
  searchTerm: string;
  isLoading = false;
  modalOptions!: MultiSelectOptionModel[];
  treeOptions!: TreeViewOptionModel[];
  treeValues: TreeViewOptionModel[] = [];
  filterTitle: string | undefined;
  filterValues: FilterValueModel[];
  filterLength: number;
  // tslint:disable-next-line:no-any
  originalTreeValues: TreeViewOptionModel[] = [];
  originalModelOptions: MultiSelectOptionModel[] = [];
  subscription: Subscription[] = [];
  sourceUrl: string;
  currentFilter: PageAnalysisFilterModel;
  currentOrgNames: string[] = [];
  treeViewConfigs = {
    childSelection : false,
    showCheckbox : true
  };
  initialTreeValues: TreeViewOptionModel[] = [];
  currentTableDepartments: {[key: string]: string}[] = [];
  public getAvailableFilterTypeOptions = TabReportService.getAvailableFilterTypeOptions;
  constructor(
    private analysisFiltersService: AnalysisFiltersService,
    private analysisService: AnalysisService,
    private cd: ChangeDetectorRef,
    private chooseDepartmentService: ChooseDepartmentService,
    public tabReport: TabReportService,
    private settings: SettingsRepositoryService
  ) {
    super();
    this.searchDepartments.pipe(debounceTime(500)).subscribe(searchTerm => {
      this.performSearch(searchTerm);
    });
  }

  ngOnInit() {
    this.chooseDepartmentService.onClear.subscribe(() => {
      this.onClearAllFilters();
    });

    
    this.analysisService.row.subscribe((rowIDS) => {
      this.treeValues = this.treeValues.filter(treeValue => rowIDS.includes(treeValue.key));
      this.originalTreeValues = cloneDeep(this.treeValues);
     });

     this.analysisService.unHideDeptId.subscribe((deptId) => {
      let node = this.initialTreeValues.filter(treeValue => deptId.includes(treeValue.key));
      this.treeValues = this.treeValues.concat(node);
      this.originalTreeValues = cloneDeep(this.treeValues);
     });

    this.settings.departmentTableData.subscribe((settings) => {
      this.currentTableDepartments = settings;
    });
  }

  performSearch(searchTerm: string): void {
    this.chooseDepartmentService.getSearchedDepartment(searchTerm, this.getDeptKeys())?.pipe(
      finalize(() => {
        this.isLoading = false;
        this.cd.detectChanges();
      })
    ).subscribe(
      response => {
        const resultsArray = response;
        if (Array.isArray(resultsArray)) {
          this.searchResponse = resultsArray.map(option => {
            const isLevel1 = option.level === '1';
            const isLevel0 = option.level === '0';
            option.parentOrg = isLevel0 ? option.name : option.parentOrg;
            const hierarchy = isLevel1 ? option.parentOrg + ' > ' + option.name :
            ((isLevel0) ? option.parentOrg : null) as string;
            return {
              ...option,
              selected: this.treeValues.some((treeOption: SearchViewOptionModel) => treeOption.key === option.key),
              parentHierarchy: hierarchy
            };
          });
        }
      }
    );
  }

  getDeptKeys() {
    return this.treeOptions.map(option => option.key);
  }

  updateSearch(e: { target: { value: string; }; }) {
    this.searchTerm = e.target.value;
    if (this.searchTerm.length > 2) {
      this.searching = true;
    } else {
      this.searching = false;
      this.searchResponse = [];
      // refresh the popup
      return;
    }
    this.isLoading = true;
    this.searchDepartments.next(this.searchTerm);
    this.cd.detectChanges();
  }
  clear() {
    this.searching =  false;
    this.searchResponse = [];
    this.redel.inputValue = '';
  }
  selectedDepartments(newDept: TreeViewOptionModel) {
    const index = this.treeValues.findIndex((existingDept: TreeViewOptionModel) => existingDept.key === newDept.key && existingDept.parentOrg === newDept.parentOrg);

    if (newDept.selected) {
      if (index === -1) {
        this.treeValues.push(newDept);
      }
    } else {
      if (index !== -1) {
        this.treeValues.splice(index, 1);
      }
    }
    this.filterValues = this.treeValues;
    this.treeViewComponent.selectValues(this.treeValues);
    this.cd.detectChanges();
  }

  private createDepartmentOption(department: DepartmentModel): DepartmentModel & MultiSelectOptionModel {
    if (department.childDepts) {
      // tslint:disable-next-line:no-any
      department.children = department.childDepts.map((dept: any) => this.createDepartmentOption(dept));
    }
    return {
      alwaysShowChildren: true,
      ...department,
      label: department.name
    };
  }

  // tslint:disable-next-line:no-any
  addChildrenToTree(treeOption: any, row: any, department: any) {
    // tslint:disable-next-line:no-any
    if (((treeOption as any).key === row.key) && ((treeOption as any).parentOrg === row.parentOrg)) {
      // tslint:disable-next-line:no-any
      let treeChildren = department.childDepts.map((dept: any) => this.createDepartmentOption(dept)) as TreeViewOptionModel[];
      treeOption.children = treeChildren;
      treeOption.childDepts = treeChildren;
      return true;
    } else if (!isUndefined(treeOption.children)) {
      if (treeOption.childDepts) {
        // tslint:disable-next-line:no-any
        treeOption.childDepts.some((option: any) => this.addChildrenToTree(option, row, department));
      }
      // tslint:disable-next-line:no-any
      return treeOption.children.some((option: any) => this.addChildrenToTree(option, row, department));
    }
    return false;
  }

  // tslint:disable-next-line:no-any
  onToggleChildren(row: any) {
    if (isUndefined(row.children)) {
      if (!isUndefined(this.sourceUrl)) {
        const subscription = this.analysisFiltersService.postRequestFilterOptions(
          this.sourceUrl,
          { src: 'dept', groupId : row.groupId, level : row.level, q: [ row.key ] },
        // tslint:disable-next-line:no-any
        ).subscribe((departments: any[]) => {
          departments.forEach((department) => {
            if (department.key === row.key && (department.parentOrg === row.parentOrg)) {
              if (!isUndefined(department.childDepts)) {
                row.children = department.childDepts;
                // tslint:disable-next-line:no-any
                let index = this.modalOptions.findIndex(treeOption => ( ((treeOption as any).key === row.key) && (treeOption as any).parentKey === row.parentKey));
                if (index > -1) {
                  const flattenedChildren = this.chooseDepartmentService.flattenDepartments(department.childDepts);
                  this.treeOptions.some((treeOption) => this.addChildrenToTree(treeOption, row, department));
                  // tslint:disable-next-line:no-any
                  let modalChildren = flattenedChildren.map((dept: any) => this.createDepartmentOption(dept)) as TreeViewOptionModel[];
                  this.modalOptions.splice(index, 0, ...modalChildren);
                  this.treeOptions = this.setParentHierarchy(this.treeOptions);
                  this.originalModelOptions = cloneDeep(this.modalOptions);
                }
                row.showChildren = true;
              } else {
                row.children = [];
              }
            }
          });
          this.treeValues = this.setParentHierarchy(this.treeValues);
          this.cd.detectChanges();
        });
        this.subscription.push(subscription);
      }
    }
  }

  private loadOptions(event: { filter: PageAnalysisFilterModel; filters: PageAnalysisFilterModel[]; }) {
    this.currentFilter = event.filter;
    let newOrgNames = event.filters.find(filter => filter.name === 'orgname')!.appliedValues!.is! as string[];
    if (!isUndefined(event.filter.source) && !newOrgNames.every(newOrgName => this.currentOrgNames.includes(newOrgName))) {
      this.currentOrgNames = newOrgNames;
      this.isLoading = true;
      this.sourceUrl = event.filter.source;
      const subscription = this.analysisFiltersService.postRequestFilterOptions(
        event.filter.source,
        Object.assign(
          { src: 'org', level : '0' },
          this.analysisFiltersService.getFilterOptionsRequestParamsForFilter(event.filter, event.filters)
        ),
      // tslint:disable-next-line:no-any
      ).subscribe((departments: any[]) => {
        if (isArray(this.treeOptions) && this.treeOptions.length > 0) {
          const newOrgs = departments
            .filter(department => !this.treeOptions.find(treeOption => department.key === treeOption.key))
            .map(dept => this.createDepartmentOption(dept)) as TreeViewOptionModel[];

          this.treeOptions = [...this.treeOptions.filter(treeOption => this.currentOrgNames.includes(treeOption.name as string)), ...newOrgs];
          this.treeOptions = this.setParentHierarchy(this.treeOptions);
          const flattenedNodes = this.chooseDepartmentService.flattenDepartments(cloneDeep(this.treeOptions) as DepartmentModel[]);
          this.modalOptions = flattenedNodes.map(dept => this.createDepartmentOption(dept));
          this.treeValues = this.treeValues.filter(treeValue => this.currentOrgNames.includes(treeValue.parentOrg as string));
        } else {
          this.treeOptions = departments.map(dept => this.createDepartmentOption(dept)) as TreeViewOptionModel[];
          this.treeOptions = this.setParentHierarchy(this.treeOptions);
          const flattenedNodes = this.chooseDepartmentService.flattenDepartments(cloneDeep(this.treeOptions) as DepartmentModel[]);
          const deptHierarchys =
            event.filters.find(filter => filter.name === 'deptHierarchy')!.appliedValues!.is! as { [key: string]: string; };
          this.originalTreeValues = isUndefined(event.filter.appliedValues) ? [] : this.getOriginalResponse(event.filter.appliedValues.is, flattenedNodes, deptHierarchys);
          this.treeValues = cloneDeep(this.originalTreeValues);
          this.modalOptions = flattenedNodes.map(dept => this.createDepartmentOption(dept));
          this.originalModelOptions = cloneDeep(this.modalOptions);
        }
        this.isLoading = false;
        this.cd.detectChanges();
      });
      this.subscription.push(subscription);
    } else {
      this.currentOrgNames = newOrgNames;
      this.treeOptions = [...this.treeOptions.filter(treeOption => this.currentOrgNames.includes(treeOption.name as string))];
      const flattenedNodes = this.chooseDepartmentService.flattenDepartments(cloneDeep(this.treeOptions) as DepartmentModel[]);
      this.modalOptions = flattenedNodes.map(dept => this.createDepartmentOption(dept));
      this.treeValues = this.treeValues.filter(treeValue => this.currentOrgNames.includes(treeValue.parentOrg as string));
    }
  }

  setParentHierarchy(dept: TreeViewOptionModel[]) {
    return dept.map((department) => {
      if (department.children) {
        // @ts-ignore
        department.children = this.setParentHierarchy(department.children);
      }

      // @ts-ignore
      if (department.childDepts) {
        // @ts-ignore
        department.childDepts = this.setParentHierarchy(department.childDepts);
      }

      if (!department.parentHierarchy) {
        let parentHierarchy = this.getParentHierarchy(this.treeOptions, department.name, department.parentOrg) as string || null;
        department.parentHierarchy = parentHierarchy as string;
      }
      if (department.parentHierarchy) {
        department.parentOrg = department.parentHierarchy.split('>')[0].trim();
      }
      // @ts-ignore
      if (department.parentOrg && !department.groupId) {
        // @ts-ignore
        department.groupId = this.treeOptions.find((treeOption) => treeOption.name === department.parentOrg)!.groupId;
      }
      return department;
    });
  }

  isFilterChanged() {
    return !(this.originalTreeValues.length === this.treeValues.length && this.originalTreeValues.every(response =>
      this.treeValues.find(treeValue => treeValue.key === response.key && treeValue.parentOrg === response.parentOrg)));
  }

  // tslint:disable-next-line:no-any
  getParentHierarchy(array: TreeViewOptionModel[], targetName: string | undefined, parentDept: any ): string | null {
        let result: string;
        // @ts-ignore
        array.some(({ name, children = [], parentOrg }: { name: string , children: TreeViewOptionModel[], parentOrg: string | null}) => {
          if (name === targetName) {
            if (parentDept === parentOrg) {
              return result = name;
            }
          }
          if (children) {
            let temp = this.getParentHierarchy(children, targetName, parentDept);
            if (temp) {
              return result = name + ' > ' + temp;
            } 
          }
        });
        // @ts-ignore
    return result;
  }

  // tslint:disable-next-line:no-any
  getOriginalResponse(appliedValues: string | string[] | number[] | { [key: string]: string; } | undefined, flattenedNodes: { key: string; }[],
                      deptHierarchys: { [key: string]: string; }) {
    // tslint:disable-next-line:no-any
    let matchedNodes: any[] = [];

    if (!isUndefined(appliedValues)) {
      let values =  Object.keys(appliedValues);

      // tslint:disable-next-line:no-any
      values.forEach((appliedValue) => {
        let isMatchingAvailable = flattenedNodes.some((node: {key: string; }) => {
          if (node.key === appliedValue) {
            matchedNodes.push(node);
            return true;
          }
          return false;
        });

        if (!isMatchingAvailable) {
          let parentHierarchyKey = Object.keys(deptHierarchys).find(key => key.includes(appliedValue));
          let parentHierarchy = '', parentOrg = '', groupId = '';
          if (parentHierarchyKey) {
            let deptData = this.currentTableDepartments.find((item) => item.key);
            parentHierarchy = deptHierarchys[parentHierarchyKey];
            if (parentHierarchy) {
              parentOrg = parentHierarchy.split('>')[0].trim();
            } else {
              if (deptData) {
                parentOrg = deptData.parentOrgName;
                parentHierarchy = deptData.parentHierarchy;
              }
            }
            // @ts-ignore
            groupId = parentHierarchyKey.replace(`${appliedValue}#`, '');
          }

          matchedNodes.push({
            key : appliedValue,
            // @ts-ignore
            name: appliedValues[appliedValue],
            parentHierarchy,
            parentOrg,
            groupId
          });
        }
      });
    }
    return matchedNodes;
  }

  // tslint:disable-next-line:no-any
  openModal(event: any) {
    this.labels = { ...this.defaultLabels, ...this.labels };
    this.loadOptions(event);
    this.overlayModal.openModal();
  }

  closeModal() {
    this.overlayModal.closeModal();
    this.cd.detectChanges();
  }

  onCancel(hideModel: boolean = true) {
    this.clear();
    this.filterValues = cloneDeep(this.originalTreeValues);
    this.filterLength = this.filterValues.length;
    this.treeValues = cloneDeep(this.originalTreeValues);
    this.modalOptions = cloneDeep(this.originalModelOptions);
    if (hideModel) {
      this.closeModal();
    }
  }

  onTreeValuesChange(event: FilterValuesSelectionModel) {
    let newValues = event.selectedOptions.filter((option) =>
      !this.treeValues.find(values => values.key === option.key && values.parentOrg === option.parentOrg));
    if (newValues && newValues.length > 0) {
      this.treeValues.push(...newValues as TreeViewOptionModel[]);
    } else {
      this.treeValues = without(this.treeValues, find(this.treeValues, {
        key: event.option.key,
        parentOrg : event.option.parentOrg
      }) as TreeViewOptionModel);
    }
    this.initialTreeValues = this.initialTreeValues.concat(this.treeValues);
    this.filterValues = event.selectedOptions;
  }

  onUpdateFilter() {
    this.state.request.pinned = this.state.request.pinned.filter((pinnedItem) => this.filterValues.some(v => v.selected && v.key === pinnedItem));
    this.removePinned.emit(this.treeOptions);
    this.clear();
    if (this.treeValues && this.treeValues.length > 0) {
      this.originalTreeValues = cloneDeep(this.treeValues);
      this.originalModelOptions = cloneDeep(this.modalOptions);
      let departments = this.treeValues.reduce((acc, value) => {
        // @ts-ignore
        acc[value.key as string] = value.name as string;
        return acc;
      }, {} as { [key: string]: string });

      let deptHierarchy = this.treeValues.reduce((acc, value) => {
        // @ts-ignore
        acc[`${value.key}#${value.groupId}` as string] = value.parentHierarchy as string;
        return acc;
      }, {} as { [key: string]: string });

      this.filterApply.emit({
        departments: {
          is: departments
        },
        deptHierarchy: {
          is: deptHierarchy
        },
        orgname : this.state.filters.filters.find(({name}) => name === 'orgname')!.appliedValues as AppliedFilterValuesModel
      });
    } else {
      this.originalTreeValues = [];
      this.filterApply.emit({
        orgname : this.state.filters.filters.find(({name}) => name === 'orgname')!.appliedValues as AppliedFilterValuesModel
      });
    }

    this.closeModal();
  }

  onClearAllFilters() {
    this.treeValues = [];
    this.filterValues = [];
    this.filterLength = 0;
    this.originalTreeValues = [];
    this.initialTreeValues = [];
  }
}